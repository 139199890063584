import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostCard from '../components/post-card/post-card';
import SEO from '../components/seo';
import { TagPostsWrapper, TagPageHeading, TagName } from './templates.style';

const Tags = ({ pageContext, data }: any) => {
  const { tag } = pageContext;
   const { edges, totalCount } = data.allStrapiArticles;

 // console.log(data.allStrapiArticles)
  

  return (
    <Layout>
      <SEO title={tag} description={`Počet příspěvků ${totalCount}`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          {`Počet ${totalCount} příspěvků`}
        </TagPageHeading>
        {edges.map(({ node, index }: any) => (
          <PostCard
            key={node.slug}
            title={node.title}
            url={node.slug}
            image={ node.cover == null ? null : node.cover.childImageSharp.fluid}
            description={node.excerpt || node.title}
            date={node.createdAt}
            tags={[node.tag]}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMMM [</span>]")
            title
            tags
            description
          }
        }
      }
    }
     
    allStrapiArticles(limit: 2000, sort: {fields: createdAt, order: DESC }, filter: {tag: {in: [$tag]}, public: {eq: true}}) {
      edges {
        node {
          tag
          cover {
            childImageSharp {
              fluid(maxWidth: 1170, maxHeight: 690, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          slug
          createdAt
          excerpt
          title
          
        }
      }
      totalCount
    }

  }
`;


/*import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostCard from '../components/post-card/post-card';
import SEO from '../components/seo';
import { TagPostsWrapper, TagPageHeading, TagName } from './templates.style';

const Tags = ({ pageContext, data }: any) => {
  const { tag } = pageContext;
  const customtag = tag
  const { edges,  } = data.allMarkdownRemark;
  const { customedges, totalCount } = data.allStrapiArticles;

 // console.log(data.allStrapiArticles)
  

  return (
    <Layout>
      <SEO title={tag} description={`Počet ${totalCount} příspěvků`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          {`Počet ${totalCount} příspěvků`}
        </TagPageHeading>
        {edges.map(({ node, index }: any) => (
          <PostCard
            key={node.fields.slug}
            title={node.frontmatter.title}
            url={node.fields.slug}
            description={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  );
};

export default Tags;*/